import React from 'react'
import Layout from '../components/Layout'
import notFoundSvg from '../img/error-404-colour.svg'
import '../components/styles/404.scss'

const NotFoundPage = () => (
  <Layout>
    <div className="content has-text-centered is-flex is-flex-direction-column is-justify-content-center is-align-items-center p-4">
      <img src={notFoundSvg} alt="404 Error Image" className="not-found" />
      <h1>We can’t seem to find the page you’re looking for</h1>
      <p>You’ve just hit a route that doesn&#39;t exist...</p>
    </div>
  </Layout>
)

export default NotFoundPage
